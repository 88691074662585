// src/gatsby-plugin-theme-ui/index.ts
import { merge } from 'theme-ui';
import baseTheme from '@lekoarts/gatsby-theme-cara/src/gatsby-plugin-theme-ui/index';

const customTheme = merge(baseTheme, {
    space: [
        0, 4, 8, 16, 32, 64, 128, 256, 512
    ],
    colors: {
        // 默认颜色（深色主题）
        background: '#141821',
        text: '#FFD700',
        primary: '#1A73E8', // 明亮的蓝色
        secondary: '#FFA726', // 活力橙
        highlight: '#FFECB3', // 浅橙色，用于高亮等
        muted: '#333333', // 深色背景下的较深色调
        navtext: '#ffffff', // 导航栏文字颜色
        navbg: 'rgba(0, 0, 0, 0.5)', // 导航栏背景颜色

        // ProjectCard 组件颜色
        // backgroundCard: 'linear-gradient(145deg, #242C39, #1A2030)', // 深色模式背景
        backgroundCard: '#141821', // 深色模式背景
        textColor: '#FFFFFF', //#FFD700（黄色）
        hoverColor: '#1A73E8',
        cardShadow: '0 2px 8px rgba(255, 255, 255, 0.1)', // 卡片阴影
        cardHoverShadow: '0 3px 8px rgba(255, 255, 255, 0.15)', // 鼠标悬停时的卡片阴影

        // Content 字体颜色
        contentColor: '#ffffff',

        // Details 组件字体颜色
        detailsColor: '#ffffff',

        // About 组件背景色
        aboutBg: '#1e2029',
        // Contact 组件背景色
        contactBg: '#1a202c',
        contactFill: '#23262f',

        // 符合网站主题的绿色和橙色
        greenColor: '#00B37E',
        orangeColor: '#FFB020',

        // 亮色主题颜色定义
        modes: {
            light: {
                background: '#ffffff',
                text: '#FFA500',
                primary: '#0077B6',
                secondary: '#00B4D8',
                highlight: '#FFD54F',
                muted: '#f6f6f6',
                navtext: '#333',
                navbg: 'rgba(255, 255, 255, 0.5)', // 导航栏背景颜色

                // ProjectCard 组件颜色
                // backgroundCard: 'linear-gradient(145deg, #E6EAF1, #F7FAFC)', // 亮色模式背景
                backgroundCard: '#ffffff',
                textColor: '#141821',
                hoverColor: '#00B4D8',
                cardShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // 卡片阴影
                cardHoverShadow: '0 3px 8px rgba(0, 0, 0, 0.15)', // 鼠标悬停时的卡片阴影

                // Content 字体颜色
                contentColor: '#737373',

                // Details 组件字体颜色
                detailsColor: '#737373',

                // About 组件背景色
                aboutBg: '#f0f2f5',
                // Contact 组件颜色
                contactBg: '#f7fafc',
                contactFill: '#f7f9fc',
                // 符合网站主题的绿色和橙色                
                greenColor: '#007A42',
                orangeColor: '#F79009',
            },
        },
    },
    styles: {
        ...baseTheme.styles,
        root: {
            backgroundColor: 'background',
            color: 'text',
            transition: 'background-color 0.2s ease-out, color 0.2s ease-out',
        },
        // Breadcrumb样式
        breadcrumb: {
            ol: {
                listStyle: 'none',
                padding: 0,
                display: 'flex',
            },
            li: {
                display: 'inline',
                marginRight: 3,
            },
            a: {
                textDecoration: 'none',
                color: 'inherit',
            }
        },
        // 表格样式
        table: {
            width: '100%',
            marginBottom: '1rem',
            borderCollapse: 'collapse',
            borderSpacing: 0,
            border: '2px solid', // 添加边框
            borderColor: 'text', // 使用主题中的边框颜色
        },
        th: {
            backgroundColor: 'muted',
            borderColor: 'contentColor',
            borderWidth: '1px',
            borderStyle: 'solid',
            padding: '0.5rem',
            textAlign: 'left',
        },
        td: {
            borderColor: 'contentColor',
            borderWidth: '1px',
            borderStyle: 'solid',
            padding: '0.5rem',
            textAlign: 'left',
            color: 'contentColor', // 使用主题中定义的文本颜色
        },
        // 导航栏样式
        nav: {
            container: {
                color: 'navtext',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 1000,

                // background: 'linear-gradient(90deg, primary 0%, secondary 100%)',//全透明
                // background: 'rgba(0, 0, 0, 0.5)', // 黑色半透明背景
                // background: 'linear-gradient(rgba(0, 77, 182, 0.8), rgba(0, 180, 216, 0.8))', // 使用带有透明度的渐变背景
                backdropFilter: 'blur(8px)', // 背景模糊效果
                backgroundColor: 'navbg', // 黑色半透明背景，与模糊效果结合

                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                padding: '1rem',
                alignItems: 'center',
                justifyContent: ['flex-start', 'center'],
                flexWrap: ['wrap', 'nowrap'],
            },
            box_ul: {
                flexDirection: ['column', 'row'],
                listStyleType: 'none',
                margin: 0,
                padding: 0,
                flexGrow: 1,
                justifyContent: ['flex-start', 'space-evenly'],
            },
            box_mode: {
                fontSize: 4,
                cursor: 'pointer',
                marginLeft: 'auto',
            },
            box_menu: {
                display: ['block', 'none'],
                fontSize: 4,
                cursor: 'pointer',
                mr: 3,
            },
            link: {
                color: 'navtext',
                textDecoration: 'none',
                p: 2,
                ':hover': {
                    color: 'secondary',
                },
            },
            button: {
                ml: [2, 3],
            },
        },
    },
});

export default customTheme;
